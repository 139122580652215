// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-corporate-finance-js": () => import("./../../../src/pages/corporate-finance.js" /* webpackChunkName: "component---src-pages-corporate-finance-js" */),
  "component---src-pages-digital-assets-js": () => import("./../../../src/pages/digital-assets.js" /* webpackChunkName: "component---src-pages-digital-assets-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-private-equity-js": () => import("./../../../src/pages/private-equity.js" /* webpackChunkName: "component---src-pages-private-equity-js" */),
  "component---src-pages-real-estate-js": () => import("./../../../src/pages/real-estate.js" /* webpackChunkName: "component---src-pages-real-estate-js" */),
  "component---src-pages-venture-capital-js": () => import("./../../../src/pages/venture-capital.js" /* webpackChunkName: "component---src-pages-venture-capital-js" */)
}

